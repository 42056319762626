@import "https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400&family=Montserrat:wght@200;400;600;800&display=swap";
:root {
  --primary: #0078d4;
  --secondary: #203a61;
}

html, body {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Kode Mono, monospace;
  overflow-x: hidden;
}

div#main {
  flex-direction: column;
  width: 100vw;
  display: flex;
}

section {
  box-sizing: border-box;
  width: 100vw;
  position: relative;
}

section.min-height {
  min-height: 100vh;
}

section.padding {
  padding: 5rem 0;
}

section img.background {
  object-fit: cover;
  pointer-events: none;
  z-index: -1;
  width: 100vmax;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

h1 {
  color: #0078d4;
  text-shadow: 0 0 8px #0078d4, 0 0 10px #0078d4;
  margin: 0;
  font-family: Kode Mono, monospace;
  font-size: 7rem;
  font-weight: 400;
  line-height: 1;
}

h2 {
  margin: 0;
  padding-top: 1rem;
  font-size: 2rem;
  font-weight: 400;
}

h3 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 400;
}

p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
}

p.large {
  font-size: 24px;
}

p.small {
  text-align: center;
  font-size: 15px;
}

hr {
  margin: 2rem;
}

li {
  line-height: 1.7;
}

li::marker {
  color: #4e567e;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #4e567e;
}

.container {
  grid-gap: 20px;
  grid-template-columns: 330px 300px;
  margin: 20px auto;
  display: grid;
}

.container .box {
  border: 1px solid var(--primary);
  text-align: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px 16px;
  display: flex;
}

img.socialIcon {
  width: 30px;
  height: 30px;
}

.navbar-item {
  color: #000;
  font-size: 16px;
  text-decoration: none;
  transition: font-size .3s;
}

.navbar-item i {
  visibility: hidden;
  opacity: 0;
  margin-right: 5px;
  transition: visibility, opacity .3s linear;
}

.navbar-item:hover i {
  visibility: visible;
  opacity: 1;
}

.contenedor-flecha {
  animation: 1.5s ease-in-out infinite moverFlecha;
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
}

.aboutDiv {
  text-align: center;
  background-color: #203a61;
  border: 5px solid #0078d4;
  border-radius: 5rem;
  width: 50%;
  margin: 3rem auto;
  padding: 4rem;
}

.skillsList {
  text-align: left;
  columns: 2;
  color: #fff;
  gap: 3rem;
  margin: 1rem 3rem;
  font-size: 1rem;
}

.navbar-item .rayo {
  visibility: hidden;
}

.navbar-item.activo .rayo {
  visibility: visible;
}

.buttonDownload {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #203a61;
  background-image: linear-gradient(45deg, #203a61, #00b4db);
  border: 1px solid #0078d4;
  border-radius: 10px;
  margin: 3rem auto;
  padding: 1rem;
  font-family: Kode Mono, monospace;
  font-size: 1.5rem;
}

.buttonDownload:hover {
  background-color: #203a61;
  background-image: linear-gradient(45deg, #203a61, #0599bb);
}

.box {
  color: #fff;
  cursor: pointer;
  background-color: #203a61;
  border: 1px solid #ccc;
  padding: 20px;
  transition: background-color .5s, background-image .5s;
}

.box span {
  transition: transform .5s;
  display: inline-block;
}

.box:hover {
  background-color: #203a61;
  background-image: linear-gradient(45deg, #203a61, #00b4db);
}

.box:hover span {
  transform: translateX(10px);
}

img.socialIcon:hover {
  filter: brightness(0) saturate() invert(27%) sepia(85%) saturate(7486%) hue-rotate(189deg) brightness(99%) contrast(119%);
}

.navbar {
  z-index: 10;
  background: #ffffffbf;
  justify-content: center;
  gap: 2rem;
  width: 100%;
  padding: 1rem;
  display: flex;
  position: fixed;
  top: 0;
}

.socials {
  justify-content: center;
  gap: 2.5rem;
  display: flex;
}

.contenedor-perfil {
  background: repeating-conic-gradient(from var(--a), #152874 0%, #152874 5%, transparent 5%, transparent 40%, #152874 50%);
  border-radius: 50%;
  width: 20rem;
  height: 20rem;
  animation: 10s linear infinite animate;
  position: absolute;
  bottom: 5rem;
  right: 5rem;
  overflow: hidden;
}

@property --a {
  syntax: "<angle>";
  inherits: false;
  initial-value: 0deg;
}

@keyframes animate {
  0% {
    --a: 0deg;
  }

  100% {
    --a: 360deg;
  }
}

.contenedor-perfil:before {
  content: "";
  background: repeating-conic-gradient(from var(--a), #0a1237 0%, #0a1237 5%, transparent 5%, transparent 40%, #0a1237 50%);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: 10s linear -3s infinite animate;
  position: absolute;
  top: 0;
  left: 0;
}

.perfil {
  box-sizing: border-box;
  border: .5rem solid #070618;
  border-radius: 50%;
  width: 90%;
  height: 90%;
  position: absolute;
  top: 5%;
  left: 5%;
}

@keyframes rotateAround {
  0% {
    transform: translate(-50%, -50%)rotate(0);
  }

  100% {
    transform: translate(-50%, -50%)rotate(360deg);
  }
}

@keyframes moverFlecha {
  0%, 100% {
    transform: translateY(0)translateX(-50%);
  }

  50% {
    transform: translateY(-10px)translateX(-50%);
  }
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #2a2a2a;
}

::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (width <= 600px) {
  .aboutDiv {
    width: 75%;
    margin: 1rem auto;
    padding: 2rem;
  }

  .skillsList {
    columns: 1;
    margin: 1rem 0;
  }

  .container {
    grid-template-columns: 1fr;
    margin: 0;
    padding: 0 2rem;
  }

  .imgPortfolio {
    display: none;
  }

  section.padding {
    padding: 2rem 0;
  }

  .navbar {
    gap: 1rem;
    padding: 1rem 0;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  .socials {
    flex-wrap: wrap;
    padding: 0 4rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h1 {
    font-size: 4.5rem;
  }

  .buttonDownload {
    margin: 2rem auto;
    padding: .75rem;
    font-size: 1rem;
  }

  .contenedor-perfil {
    width: 15rem;
    height: 15rem;
    bottom: 7rem;
    right: 25%;
  }
}
/*# sourceMappingURL=index.55c29499.css.map */
