/**
* CSS file to hold styles that apply across the application
*/
@import url('https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400&family=Montserrat:wght@200;400;600;800&display=swap");

:root {
  --primary: #0078d4;
  --secondary: #203a61;
}

html,
body {
  font-family: "Kode Mono", monospace;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
}

div#main {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

section {
  position: relative;
  width: 100vw;
  box-sizing: border-box;
}

section.min-height {
  min-height: 100vh;
}

section.padding {
  padding: 5rem 0;
}

section img.background {
  height: 100%;
  left: 50%;
  object-fit: cover;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translate(-50%, 0);
  width: 100vmax;
  z-index: -1;
}

h1 {
  font-family: "Kode Mono", monospace;
  font-weight: 400;
  font-size: 7rem;
  color: #0078d4;
  text-shadow:
    0 0 8px #0078d4,
    0 0 10px #0078d4;
  line-height: 1;
  margin: 0;
}

h2 {
  font-size: 2rem;
  font-weight: 400;
  margin: 0;
  padding-top: 1rem;
}

h3 {
  font-weight: 400;
  font-size: 1.25rem;
  margin: 0;
}

p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 0;
  font-weight: 300;
}

p.large {
  font-size: 24px;
}

p.small {
  font-size: 15px;
  text-align: center;
}

hr {
  margin: 2rem;
}

li {
  line-height: 1.7;
}

li::marker {
  color: #4e567e;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: #4e567e;
}

.container {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 330px 300px;
  margin: 20px auto;
}

.container .box {
  border-radius: 10px;
  border: 1px solid var(--primary);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px 16px;
  text-align: center;
}

img.socialIcon {
  height: 30px;
  width: 30px;
}

.navbar-item {
  text-decoration: none;
  color: #000;
  font-size: 16px;
  transition: font-size 0.3s ease;
}

.navbar-item i {
  margin-right: 5px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
}

.navbar-item:hover i{
  visibility: visible;
  opacity: 1;
}

.contenedor-flecha {
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%); /* Centra la flecha horizontalmente */
  animation: moverFlecha 1.5s ease-in-out infinite;
}

.aboutDiv{
  background-color: #203a61;
  width: 50%;
  padding: 4rem;
  margin: 3rem auto;
  text-align: center;
  border-radius: 5rem;
  border: 5px solid #0078d4;
}

.skillsList{
  text-align: left;
  columns: 2;
  font-size: 1rem;
  margin: 1rem 3rem;
  gap: 3rem;
  color: white;
}

.navbar-item .rayo {
  visibility: hidden;
}

.navbar-item.activo .rayo {
  visibility: visible;
}

.buttonDownload{
  color: white;
  background-color: #203a61; /* Cambia el color de fondo al pasar el ratón */
  background-image: linear-gradient(45deg, #203a61, #00b4db);
  padding: 1rem 1rem;
  margin: 3rem auto;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #0078d4;
  font-family: "Kode Mono", monospace;
  font-size: 1.5rem;
  cursor: pointer;
}

.buttonDownload:hover{
  background-color: #203a61; /* Cambia el color de fondo al pasar el ratón */
  background-image: linear-gradient(45deg, #203a61, #0599bb);
}

.box {
  padding: 20px; /* Espacio alrededor del texto */
  border: 1px solid #ccc;
  transition: background-color 0.5s ease, background-image 0.5s ease; /* Transición suave del fondo y del gradiente */
  background-color: #203a61; /* Color de fondo original */
  color: #fff;
  cursor: pointer;
}

.box span {
  transition: transform 0.5s ease; /* Define la duración y el tipo de transición */
  display: inline-block; /* Necesario para aplicar transformaciones */
}

.box:hover{
  background-color: #203a61; /* Cambia el color de fondo al pasar el ratón */
  background-image: linear-gradient(45deg, #203a61, #00b4db); /* Cambia el gradiente al pasar el ratón */
}

.box:hover span {
  transform: translateX(10px); /* Mueve el span hacia la derecha */
}

img.socialIcon:hover{
  filter: brightness(0) saturate(100%) invert(27%) sepia(85%) saturate(7486%) hue-rotate(189deg) brightness(99%) contrast(119%);
}

.navbar{
  position: fixed;
  display: flex;
  justify-content: center;
  gap: 2rem;
  background: rgba(255,255,255,0.75);
  padding: 1rem;
  top: 0;
  width: 100%;
  z-index: 10;
}

.socials{
  display: flex;
  justify-content: center;
  gap: 2.5rem;
}

.contenedor-perfil{
  position: absolute;
  right: 5rem; /* Alinea el contenedor a la izquierda */
  bottom: 5rem; /* Alinea el contenedor al fondo */
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  overflow: hidden;
  background: repeating-conic-gradient(from var(--a), #152874 0%, #152874 5%, transparent 5%, transparent 40%, #152874 50%);
  animation: animate 10s linear infinite;
}

@property --a{
  syntax: "<angle>";
  inherits: false;
  initial-value: 0deg;
}

@keyframes animate {
  0% {
    --a: 0deg;
  }
  100% {
    --a: 360deg;
  }
}

.contenedor-perfil::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: repeating-conic-gradient(from var(--a), #0a1237 0%, #0a1237 5%, transparent 5%, transparent 40%, #0a1237 50%);
  animation: animate 10s linear infinite;
  animation-delay: -3s;
}

.perfil {
  width: 90%; /* Reduce el ancho para compensar el ancho del borde */
  height: 90%; /* Reduce la altura para compensar el ancho del borde */
  border-radius: 50%; /* Mantiene los bordes redondos */
  position: absolute;
  top: 5%; /* Posiciona el elemento centrado verticalmente */
  left: 5%; /* Posiciona el elemento centrado horizontalmente */
  border: 0.5rem solid #070618; /* Agrega un borde blanco de 2px de grosor */
  box-sizing: border-box; 
}

@keyframes rotateAround {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes moverFlecha {
  0%, 100% {
    transform: translateY(0) translateX(-50%);
  }
  50% {
    transform: translateY(-10px) translateX(-50%);
  }
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #2a2a2a;
}

::-webkit-scrollbar-thumb {
  background: #666; /* Color del thumb */
  border-radius: 6px; /* Borde redondeado para el thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color del thumb al pasar el ratón */
}

@media (max-width: 600px) {
  /* Tus estilos aquí */
  .aboutDiv{
    width: 75%;
    padding: 2rem;
    margin: 1rem auto;
  }

  .skillsList{
    columns: 1;
    margin: 1rem 0;
  }

  .container{
    margin: 0;
    grid-template-columns: 1fr;
    padding: 0 2rem; 
  }

  .imgPortfolio{
    display: none;
  }

  section.padding{
    padding: 2rem 0;
  }

  .navbar{
    gap: 1rem;
    padding: 1rem 0;
  }

  ::-webkit-scrollbar{
    width: 6px;
  }

  .socials{
    flex-wrap: wrap;
    padding: 0 4rem;
  }

  h2{
    font-size: 1.5rem;
  }

  h1{
    font-size: 4.5rem;
  }

  .buttonDownload{
    font-size: 1rem;
    margin: 2rem auto;
    padding: 0.75rem;
  }

  .contenedor-perfil{
    right: 25%;
    bottom: 7rem;
    width: 15rem;
    height: 15rem;
  }
}

